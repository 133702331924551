import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import { format } from 'helpers/string'
import {toFormData} from "../../helpers/form";

export async function listDiscountRule(req) {
  return apiClient
    .get(urls.discount.list, { params: req })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function readDiscountRule(ruleId) {
  const url = format(urls.discount.delete, [ruleId])

  return apiClient
    .get(url)
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}

export async function createDiscountRule(req) {
  return apiClient
    .post(urls.discount.create, req)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}

export async function updateDiscountRule(req) {
  return apiClient
    .put(urls.discount.update, req)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function deleteDiscountRule(ruleId) {
  const url = format(urls.discount.delete, [ruleId])

  return apiClient
    .delete(url)
    .then((response) => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function loadDiscount(req) {
  const formData = toFormData(req)

  return apiClient
    .post(urls.discount.load, formData, {
      timeout: 60000,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => {
      return !!response
    })
    .catch(err => console.log(err))
}

export async function downloadSampleLoadExcel() {
  return apiClient
    .get(urls.discount.sampleLoadExcel)
    .then(response => {
      if (response) {
        return response.data
      }
      return null
    })
    .catch(err => console.log(err))
}
