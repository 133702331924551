const actions = {
  LIST_DISCOUNT: 'discount/LIST_DISCOUNT',
  READ_DISCOUNT: 'discount/READ_DISCOUNT',
  CREATE_DISCOUNT: 'discount/CREATE_DISCOUNT',
  UPDATE_DISCOUNT: 'discount/UPDATE_DISCOUNT',
  DELETE_DISCOUNT: 'discount/DELETE_DISCOUNT',
  LOAD_DISCOUNT: 'discount/LOAD_DISCOUNT',
  SAMPLE_EXCEL: 'discount/SAMPLE_EXCEL',
  CLEAR_DISCOUNT: 'discount/CLEAR_DISCOUNT',
  SET_STATE: 'discount/SET_STATE',
  ADD_STATE: 'discount/ADD_STATE',
  DELETE_STATE: 'discount/DELETE_STATE',
}

export default actions
