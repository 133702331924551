import apiClient from 'services/axios'
import urls from 'services/axios/ApiUrl'
import qs from "qs";

export async function getOrderStatisticsForAll(queryParams) {
  return apiClient
    .get(urls.statistics.order.all, {
      params: queryParams,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    })
    .then(response => {
      if (response) {
        return response.data
      }
      return []
    })
    .catch(err => console.log(err))
}
